//=include ../../bower_components/opentip/lib/opentip.js
//=include ../../bower_components/opentip/lib/adapter-jquery.js

// A simple wrapper for all your custom jQuery that belongs in the footer
;(function($){
  $(function(){
    var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};
    var hamburgers = document.querySelectorAll(".hamburger");
    var canvas = document.querySelectorAll(".canvas");
    if (hamburgers.length > 0) {
      forEach(hamburgers, function(hamburger) {
        hamburger.addEventListener("click", function() {
          /*$(".hamburger").each(function(){
            this.classList.toggle("is-active");
          });*/
          $(".hamburger").toggleClass("is-active");
          canvas[0].classList.toggle("is-shifted");
        }, false);
      });
    }
  });
}(jQuery));
